export const useShop = () => {
  const storyblokApiOptions = useStoryblokApiOptions();
  const storyblokApi = useStoryblokApi();
  const { localeStoryName } = useUtils();
  const { locale } = useI18n();
  const { getSignedUrl } = useProtectedAssets();
  const { subscribeUser, getListMembersInfo } = useMailchimp();

  const { $store } = useNuxtApp();
  const downloadAccess = ref(false);
  const downloads = ref([]);

  const filteredItems = ref([]);

  const resource = ref("");
  function setResource(_resource: string) {
    resource.value = _resource;
  }

  async function config(params = {}) {
    const { data } = await storyblokApi.get("cdn/stories/whitepapers", {
      ...storyblokApiOptions,
      ...params,
    });

    return localeStoryName(data.story);
  }

  async function items() {
    const filters = {};

    const { data } = await storyblokApi.get("cdn/stories", {
      ...storyblokApiOptions,
      starts_with: resource.value,
      is_startpage: 0,
      language: locale.value,
      filter_query: {
        component: {
          in: "shop-item",
        },
        ...filters,
      },
      sort_by: "first_published_at:asc",
    });

    return data.stories
      .filter(
        (story) =>
          locale.value === "de" ||
          story.translated_slugs.find(
            (translation) =>
              translation.lang === story.lang && translation.published,
          ),
      )
      .map((story) => localeStoryName(story));
  }

  async function find(uuid: string) {
    const storyblokLinks = computed(() => $store.getters.getLinks);
    const link = storyblokLinks.value[uuid];

    const { data } = await storyblokApi.get(`cdn/stories/${link.slug}`, {
      ...storyblokApiOptions,
    });

    return localeStoryName(data.story);
  }

  async function categories() {
    const { data } = await storyblokApi.get("cdn/stories", {
      ...storyblokApiOptions,
      starts_with: "shop/kategorien",
      is_startpage: 0,
    });

    return data.stories.map((story) => localeStoryName(story));
  }

  function findCategory(uuid: string) {
    const storyblokLinks = computed(() => $store.getters.getLinks);
    const link = storyblokLinks.value[uuid];

    return link;
  }

  function mainImage(item) {
    return item.content.images?.[0] ? item.content.images?.[0] : null;
  }

  function link(item) {
    return `/${item.full_slug}`;
  }

  async function setCategory(category) {
    $store.dispatch("setCategory", category);
    await updateFilteredItems();
  }

  async function updateFilteredItems() {
    filteredItems.value = await items();
  }

  async function handleFreeDownload(
    email,
    { error, story = $store.getters.getStory } = {},
  ) {
    if (email !== "") {
      try {
        error = false;
        const response = await subscribeUser(email);

        if (response?.id || response?.data?.status === "already_subscribed") {
          await loadProtectedDownloads(story);
          downloadAccess.value = true;
        } else {
          error = true;
        }

        return response;
      } catch (error) {
        error = true;
      }
    } else {
      error = true;
    }
  }

  async function loadProtectedDownloads(
    story: object = $store.getters.getStory,
  ) {
    if (!story.content?.downloads?.length) {
      console.error("No downloads found in story", story);
    }

    const _downloads = await Promise.all(
      story.content.downloads
        .filter((download) => download.filename)
        .map(async (download) => {
          return {
            ...download,
            filename: await getSignedUrl(download.filename),
          };
        }),
    );
    downloads.value = _downloads;
  }

  async function checkCookieForDownloadAccess() {
    const cookie = useCookie("zweikern_newsletter");
    if (cookie.value === "subscribed") {
      await loadProtectedDownloads();
      downloadAccess.value = true;
    }
  }

  onMounted(async () => {
    filteredItems.value = await items();
    await checkCookieForDownloadAccess();
  });

  return {
    config,
    setResource,
    items,
    filteredItems,
    find,
    mainImage,
    link,
    findCategory,
    categories,
    setCategory,

    handleFreeDownload,
    downloadAccess,
    downloads,
  };
};
