import StoryblokClient from "storyblok-js-client";
export const useProtectedAssets = () => {
  const runtimeConfig = useState("runtimeConfig");

  const Storyblok = new StoryblokClient({
    accessToken: runtimeConfig.value.public.storyblok.assetAccessToken,
  });

  async function getSignedUrl(filename) {
    const response = await Storyblok.get("cdn/assets/me", {
      filename,
    });

    return response.data.asset.signed_url;
  }

  return {
    getSignedUrl,
  };
};
